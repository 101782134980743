








































































import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Banner } from '@/domain/entities/Banner'
import Badge from '@/app/ui/components/Badge/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import Modal from '@/app/ui/components/Modal/index.vue'

@Component({
  components: {
    Badge,
    EditIcon,
    Modal
  },
})
export default class BannerItem extends Vue {
  @Prop() private isGuest!: boolean
  @Prop() private banner!: Banner
  @Prop() private orderBanner!: number

  isVisible = false

  get statusBadgeType(): string {
    if (this.banner.status?.toLowerCase() === 'cancelled') {
      return 'error'
    } else if (this.banner.status?.toLowerCase() === 'expired') {
      return 'expire'
    } else if (this.banner.status?.toLowerCase() === 'active') {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get hideBtnEdit(): boolean {
    return this.banner.status !== 'Cancelled' && this.banner.status !== 'Expired'
  }

  private getBannerDescription(text: string) {
    return text.length > 140 ? `${text.substring(0, 140)}...` : text
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMMM YYYY[\n]HH:mm [WIB]')
  }

  private onEditBanner() {
    if (this.orderBanner === 0) {
      this.isVisible = true
    } else {
      this.$router.push(`banner-in-app/${this.banner.id}/edit`)
    }
  }

  private onCloseModal() {
    this.isVisible = false
  }
}
