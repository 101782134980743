











































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/BannerController'
import BannerItem from '../components/BannerItem/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import { Utils } from '@/app/infrastructures/misc'
import { BANNER_PAGINATION } from '@/app/infrastructures/misc/Constants'

interface IParams {
  page: number
  perPage: number
  keyword: string | null
  isActive: boolean
}

interface IParamsData extends IParams {
  sortBy: { label: string; value: string }
  filterBy: { label: string; value: string }
}

interface IParamsValue extends IParams {
  sortBy: string
  filterBy: string
}

type fakeDictionary<T> = { [key: string]: T }

@Component({
  components: {
    BannerItem,
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    AddCircle,
    Tabs,
    Modal
  },
})
export default class Banner extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  filterOptions = [
    {label: 'Show All', value: ''},
    {label: 'Info', value: 'info'},
    {label: 'Promo', value: 'promo'},
    {label: 'Other', value: 'other'},
  ]
  sortOptions = [
    {label: 'Latest', value: 'latest'},
    {label: 'Ended', value: 'ended'},
  ]
  currentTab = 0
  pageActive = 1
  pageInactive = 1
  isVisible = false
  itemTabs = [
    {label: 'Active', value: 0},
    {label: 'Not Active', value: 1}
  ]
  parameters: IParamsData = {
    page: 1,
    perPage: BANNER_PAGINATION,
    keyword: null,
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
    isActive: true
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || BANNER_PAGINATION,
        keyword: queries.keyword,
        sortBy:
          this.sortOptions.find((item) => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterBy:
          this.filterOptions.find((item) => item.value === queries.filterBy) ||
          this.filterOptions[0],
        isActive: queries.isActive === 'true'
      }
      if (queries.isActive === 'false') {
        this.currentTab = 1
      } else {
        this.currentTab = 0
      }
    }

    this.fetchBanners()
    controller.getOrderBanner()
  }

  get params(): IParamsValue {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterBy: this.parameters.filterBy.value,
      isActive: this.currentTab === 0
    }
  }
  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    if (val === '') {
      this.fetchBanners()
    }
  }

  @Watch('params')
  onParamsChanged(val: fakeDictionary<string | undefined>): void {
    this.$router.replace({
      query: {...val},
    })
  }

  private onTabsClick(tab: number): void {
    this.currentTab = tab
    if (tab === 0) {
      this.pageInactive = this.parameters.page
      this.parameters.page = this.pageActive
    } else {
      this.pageActive = this.parameters.page
      this.parameters.page = this.pageInactive
    }
    this.fetchBanners()
  }

  private onCreateBanner(): void {
    if (!this.isGuest && controller.orderBanner.value === 0) {
      this.isVisible = true
    } else if (!this.isGuest && controller.orderBanner.value !== 0) {
      this.$router.push(`/banner/banner-in-app/create`)
    }
  }

  private fetchBanners(reset?: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }
    controller.getBannerList(this.params)
  }

  private onCloseModal(): void {
    this.isVisible = false
  }
}
